'use client'
import * as React from 'react'

import Link from 'next/link'

interface IButtonProps {
  text: string
  url: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

const AButton = (props: IButtonProps) => {
  const { text, url, onClick } = props

  return (
    <Link
      href={url}
      className="mx-4 bg-secondary-400 text-white  py-2 px-12 rounded-lg text-center cursor-pointer hover:bg-primary-500 delay-[100ms] duration-[400ms] uppercase"
      onClick={onClick}
    >
      {text}
    </Link>
  )
}

export default AButton

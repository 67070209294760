'use client'
import React, { ReactNode } from 'react'
import { useRouter } from 'next/navigation'
import Link, { LinkProps } from 'next/link'

import { setTimeoutPromise } from '@/lib/standard';

interface IProps extends LinkProps {
  children: ReactNode
  href: string
  targetClass: string
  className: string
}

const TransitionLink = ({ children, href, targetClass, ...props }: IProps) => {
  const router = useRouter()
  const handleTransition = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()

    const body = document.querySelector('body')
    body?.classList.add(targetClass)

    await setTimeoutPromise(() => { router.push(href) }, 500)

    body?.classList.remove(targetClass)
  }

  return (
    <Link onClick={handleTransition} href={href} {...props}>
      {children}
    </Link>
  )
}

export default TransitionLink
